.container {
  /* white */
  background: var(--whiteColor);

  /* sha */
  box-shadow: var(--tealBoxShadow) !important;
  border-radius: var(--borderRadius) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mealTableBody {
  display: "flex";
  width: 100%;

  padding: 10px;
}

.cardHeaderr {
  background-color: unset !important;
  font-weight: 700 !important;
  text-align: left;
  padding: 2px 3px !important;
  border-top-right-radius: 9px !important;
  border-top-left-radius: 9px !important;
  max-width: 40% !important;
}

.mealCard-header {
  background-color: white;
  width: 50%;
  margin-left: 30px !important;
  border-radius: 5px 0px 0px 5px;
  padding: 10px;
}

.header {
  padding-left: 30px;
}

.Row {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  /* justify-content: flex-start !important; */
}
.btn-position {
  display: flex !important;
  justify-content: space-between !important;
  width: 100%;
  padding-bottom: 10px;
}
.TableRow {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 10px;
  justify-content: flex-start !important;
}

.base-btn {
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  padding: 10px 26px !important;
  text-transform: none !important;
  border-radius: 10px !important;
}

.baseButton {
  margin-left: 10px !important;
}

.baseButton-filter {
  background-color: rgba(85, 194, 197, 0.52) !important;
}

.dining-table-column {
  border: 1px solid black;
  height: 200px;
}

.dining-table-bg-color {
  background-color: #55c2c5;
  color: white;
}

.dining-table-cell {
  height: 50px !important;
  border: 1px solid black;
  padding: 10px !important;
  text-align: center;
}

.dining-table .css-15x21ad-MuiInputBase-root-MuiOutlinedInput-root {
  height: 50px !important;
  border-radius: 0 !important;
  border: 1px solid black !important;
}

.dining-table .MuiInputBase-root {
  height: 50px !important;
  border-radius: 0 !important;
  border: 1px solid black !important;
}

.icons-Tabs .css-ascpo7-MuiStepLabel-root {
  display: contents !important;
  width: 50% !important;
}

.icon-tab-style {
  margin: auto;
  border: var(--border);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 8px;
  color: var(--tealColor);
}

.icon-tab-style-active {
  margin: auto;
  border: var(--border);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 8px;
  color: white;
  background-color: var(--tealColor);
}

.icons-Tabs .css-1bz8pmn-MuiStepLabel-label {
  padding: 4px !important;
}

.bulkWeightInput .MuiDataGrid-cellContent {
  width: 350px !important;
  height: 35px;
  border-color: #a3a3a3 !important;
  color: #a3a3a3 !important;
  border: 1px solid #a3a3a3;
  border-radius: 10px;
  padding: 5px;
}

.print-container {
  border: var(--border2);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.resident-allergy-warning {
  color: var(--redColor);
  border: 1px solid var(--redColor);
  padding: 10px;
  margin: 20px !important;
  width: 90%;
}

.leftActions {
  width: 100%;
  justify-content: left;
}

.rightActions {
  width: 100%;
  justify-content: right;
}

.mealcard-container {
  padding: "5px";
  margin-top: "10px";
}
