.communityContainer {
  border: 1px solid #636363;
  border-top: none;
  padding: 50px;
  border-radius: 0px 0px 10px 10px;
}

.communityValue {
  border: 1px solid #636363;
  width: 90%;
  height: 40px;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.communityInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #636363;
  background-color: #fcfcfc;
  color: "#000000";
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;
}

.iscommunityInfo {
  border-bottom-width: 90%;
  border-radius: 10px 10px 0px 0px;
}

.communityInfo .material-icons {
  line-height: 0px;
  font-size: 50px;
}

.title {
  font-weight: 700 !important;
  color: black;
}
