.customMenuUpperBody {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*justify-content: space-evenly;*/
  font-weight: bold;
  font-size: 18px;
  height: 145px;
}

.upperBodyLeftColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.upperBodyRightColumn {
  display: flex;
  flex-direction: row;
}

.customMenuLowerBody {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.styledColumn {
  height: max-content;
  min-height: 100px;
  width: 100%;
  margin: 5px 0;
  border: var(--border);
  border-radius: var(--borderRadius);
  padding: 4px;
  box-shadow: var(--tealBoxShadow);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.groupingColumn {
  background-color: var(--tealColor);
}

.unGroupedColumn {
  background-color: var(--yellowColor);
}
